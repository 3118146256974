<template>
<div class="before-after--lg">
  <VueCompareImage leftLabel="Theirs" leftImage="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/Data%2FScreen%20Shot%202020-10-20%20at%2012.46.11%20PM%20(1)-min.png?alt=media&token=6c938f23-ba33-4855-9b06-0afa5ad6509e" rightLabel="Ours" rightImage="https://firebasestorage.googleapis.com/v0/b/vericool-53dd0.appspot.com/o/Data%2FScreen%20Shot%202020-10-20%20at%2012.46.28%20PM%20(1)-min.png?alt=media&token=9bae1003-36b4-4a71-9540-47af86517a5b" />
</div>
</template>

<script>
import VueCompareImage from 'vue-compare-image';

export default {
  components: { VueCompareImage },
};
	
</script>